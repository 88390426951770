import React, { useState } from "react";
import { Layout, Row, Col, Modal, Typography, Card } from "antd";
import { Content } from "antd/es/layout/layout";
import "antd/dist/antd.css";
// import "..assets/style/aboutus.css";
import HomeHeader from "../components/HomeHeader";
import HomePageFooter from "../components/HomePageFooter";
import SignIn from "./SignIn";
import "../assets/styles/signinModal.css";
import "../assets/styles/homepagefooter.css";
import "../assets/styles/aboutus.css";
import "../assets/styles/product.css";

import financial from "../assets/aboutus/financialLitracy.svg";
import tradingSol from "../assets/products/tradingSolution.svg";
import chicago from "../assets/products/chicago.svg";
import digital_palt from "../assets/products/digitalplatform.svg";

const Products = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Layout className="layout-dashboard">
        <HomeHeader showModal={showModal} />
        <Content>
          <div className="site-layout-content">
            <div className="company-overview">
              <div className="overlay">
                <Row justify="center" align="middle" className="content-row">
                  <Col xs={24} sm={18} md={14} lg={12}>
                    <div className="content-box">
                      <h1>Mission</h1>
                      <Row gutter={[16, 16]} className="company-details">
                        {/* <Col span={48}> */}
                        <div>
                          <p>
                        Our mission with Shankh is to enhance your wealth by simplifying financial management.
                        Shankh eliminates clutter and offers holistic financial wellness solution.
                        we are initially building a robust foundation to deliver a comprehensive and accessible wealth management solution for everyone.
                          </p>
                        </div>
                        {/* </Col> */}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            </div>

            <div className="products-container">
              <Row gutter={[16, 18]} className="product-row">
                <Col xs={24} md={8} className="image-col order-1-md">
                  <img src={tradingSol} alt="Advanced Investment Solutions" />
                </Col>
                <Col xs={24} md={16} className="text-col order-2-md">
                  <div className="product-text">
                    <h4>Advanced Investment Solutions</h4>
                    <p>
                      Investment requires analysis and pattern recognition based on a massive
                      amount of historical data. It is a challenging task for
                      the human mind to consider all the moving pieces.
                      Artificial intelligence can analyze huge data and
                      make more accurate predictions in real-time. With this, investors can make
                      sound decisions that manage risk better and yield
                      better returns.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 18]} className="product-row">
                <Col xs={24} md={16} className="text-col order-1-md">
                  <div className="product-text">
                    <h4>Holistic Financial Advisory for All</h4>
                    <p>
                    Our Holistic Advisory Services provide clients with personalized financial guidance
                    that encompasses all aspects of their financial life. From investment strategies and
                    retirement planning to tax optimization, our advisors offer
                    tailored solutions to meet individual goals. By adopting a holistic approach,
                    we ensure that all financial decisions are aligned with the client's overall objectives.
                    Our use of advanced analytics and AI-driven insights allows us to provide deep,
                    actionable advice that adapts to changing market conditions and personal circumstances,
                    helping clients to achieve sustained financial well-being.
                    </p>
                  </div>
                </Col>
                <Col xs={24} md={8} className="image-col order-2-md">
                  <img src={chicago} alt="Compliance Solution" />
                </Col>
              </Row>
              <Row gutter={[16, 18]} className="product-row">
                <Col xs={24} md={8} className="image-col order-1-md">
                  <img src={financial} alt="Compliance Solution" />
                </Col>
                <Col xs={24} md={16} className="text-col order-2-md">
                  <div className="product-text">
                    <h4>Compliance Solution</h4>
                    <p>
                    Our Compliance Solution is designed to help financial institutions
                    navigate the complex landscape of regulatory requirements with ease.
                    Leveraging advanced AI and machine learning algorithms, our platform
                    continuously monitors transactions, identifies potential compliance issues,
                    and generates real-time reports to ensure adherence to regulatory standards.
                    This proactive approach not only minimizes the risk of non-compliance but
                    also enhances employees financial wellness.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 18]} className="product-row">
                <Col xs={24} md={16} className="text-col order-1-md">
                  <div className="product-text">
                    <h4>Digital Platform for Advisors</h4>
                    <p>
                      we offer a digital platform to advisors
                      which helps in providing optimal investment advice to
                      their clients. The Portfolio Doctor feature is a
                      distinctive highlight and is appreciated on this platform.
                    </p>
                  </div>
                </Col>
                <Col xs={24} md={8} className="image-col order-2-md">
                  <img src={digital_palt} alt="Digital Platform for Advisors" />
                </Col>
              </Row>
              <Row gutter={[16, 18]} className="product-row">
                              <Col xs={24} md={8} className="image-col order-1-md">
                                <img src={financial} alt="White Label Wealth Desks for Small/Mid-size banks" />
                              </Col>
                              <Col xs={24} md={16} className="text-col order-2-md">
                                <div className="product-text">
                                  <h4>White Label Wealth Desks for Small/Mid-size banks</h4>
                                  <p>
                                    Shankh offers white-label solutions for small/mid-size banks looking to enhance their
                                    wealth management services. Our AI-powered platform can be integrated seamlessly,
                                    enabling banks to provide cutting-edge financial products to their customers.
                                  </p>
                                </div>
                              </Col>
                            </Row>

            </div>
        </Content>
      </Layout>
      <HomePageFooter />
      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="full-screen-modal"
        width="100%"
        style={{ top: 0, padding: 0 }}
        bodyStyle={{ height: "100vh", padding: 0, margin: 0 }}
      >
        <SignIn onClose={handleCancel} />
      </Modal>
    </>
  );
};

export default Products;
