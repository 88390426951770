import React, { useState } from "react";
import { Layout, Row, Col, Modal, Typography, Card, Button } from "antd";
import { Content } from "antd/es/layout/layout";
import "antd/dist/antd.css";
// import "..assets/style/aboutus.css";
import HomeHeader from "../components/HomeHeader";
import HomePageFooter from "../components/HomePageFooter";
import SignIn from "./SignIn";
import "../assets/styles/signinModal.css";
import "../assets/styles/homepagefooter.css";
import "../assets/styles/aboutus.css";
import purpose from "../assets/images/purpose.svg";
import portfolio from "../assets/images/shankh-small.svg";
import objective from "../assets/images/objective.svg";
import ceoProfile from "../assets/aboutus/founder.svg";
import teamProfile from "../assets/aboutus/team.svg";
import goal from "../assets/aboutus/primaryGoal.svg";
import e_projection from "../assets/aboutus/economicProjection.svg";
import user_B from "../assets/aboutus/userBenefit.svg";
import freePlatform from "../assets/aboutus/freeplatform.svg";
import financial from "../assets/aboutus/financialLitracy.svg";
import cover from "../assets/aboutus/coverPage.svg";
import "../assets/styles/signinModal.css";
const { Title, Text } = Typography;

const Aboutus = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Layout className="layout-dashboard">
        <HomeHeader showModal={showModal} />
        <Content>
          <div className="site-layout-content">
            <div className="company-overview">
              <div className="overlay">
                <Row justify="center" align="middle" className="content-row">
                  <Col xs={24} sm={18} md={14} lg={10}>
                    <div className="content-box">
                      <h1>COMPANY OVERVIEW</h1>
                      <Row gutter={[16, 16]} className="company-details">
                        <Col span={12}>
                          <div>
                            <h6>Company Name</h6>
                            <p>InfinityPool</p>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div>
                            <h6>Industry</h6>
                            <p>Fintech</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            </div>

            <div className="focus-area">
              <h2 className="focus-title">Focus Area</h2>
              <Row
                gutter={[16, 16]}
                justify="center"
                className="focus-hexagon-conatiner"
              >
                {/* <Col xs={24} sm={12} md={8}> */}
                <div className="hexagon">
                  <div className="hexagon-inner">
                    <div className="hexagon-content">Wealth Management</div>
                  </div>
                </div>
                {/* </Col> */}
                {/* <Col xs={24} sm={12} md={8}> */}
                <div className="hexagon">
                  <div className="hexagon-inner">
                    <div className="hexagon-content">Financial Education</div>
                  </div>
                </div>
                {/* </Col> */}
                {/* <Col xs={24} sm={12} md={8}> */}
                <div className="hexagon">
                  <div className="hexagon-inner">
                    <div className="hexagon-content">
                      Technology Buildout for Finance
                    </div>
                  </div>
                </div>
                {/* </Col> */}
              </Row>
            </div>

            <div className="flagship-product">
              <h2>Flagship Product: &nbsp; SHANKH</h2>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <div className="feature-card">
                    <div className="feature-card-img">
                      <img src={purpose} alt="Purpose" />
                    </div>
                    <div className="feature-card-text">
                      <h4>Purpose</h4>
                      <p>
                        Shankh's purpose is to empower Wealth Growth for a Viksit Bharat by 2047.
                        Our mission is to provide everyone with the tools and knowledge for financial growth.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <div className="feature-card">
                    <div className="feature-card-img">
                      <img src={portfolio} alt="Name Origin" />
                    </div>
                    <div className="feature-card-text">
                      <h4>Name Origin</h4>
                      <p>
                        It is derived from traditional Indian belief associating
                        'Shankh' (Conch) with wealth, prosperity, and longevity.
                        It symbolizes our commitment to enhancing your financial well-being and prosperity.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <div className="feature-card">
                    <div className="feature-card-img">
                      <img src={objective} alt="Objective" />
                    </div>
                    <div className="feature-card-text">
                      <h4>Objective</h4>
                      <p>
                        Shankh is designed to democratize access to sophisticated wealth management
                        strategies. By utilizing a highly advanced AI-based digital ecosystem, our
                        objective is to reduce complexities of finance so investors focus on life goals!
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="founder-info">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="ceo-details">
                    <img
                      src={ceoProfile}
                      alt="Founder"
                      className="founder-image"
                    />
                    <h2>Founder & CEO: Akshay Kunkulol</h2>
                    <a
                      href="https://akshaykunkulol.me/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      akshaykunkulol.me
                    </a>
                  </div>
                </Col>

                <Col xs={24} sm={24} md={12} lg={16}>
                  <div className="founder-details">
                    <div className="founder-text">
                      <div className="founder-text-title">
                        <text>Expertise & Experience:</text>
                      </div>
                      <div>
                        <text>
                          Extensive background in finance, technology, operations, strategy and product management.<br/>
                          Engineering degree in Technology and Honors MBA in Finance & Strategy from Chicago Booth.<br/>
                          Global experience in the USA, Japan, and India.
                        </text>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <br/>
              <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="ceo-details">
                        <img
                          src={teamProfile}
                          alt="Founder"
                          className="founder-image"/>
                        <h2>SHANKH team</h2>
                      </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={16}>
                    <div className="founder-details">
                      <div className="founder-text">
                        <div className="founder-text-title">
                          <text>Expertise & Experience:</text>
                        </div>
                        <div>
                          <text>
                            Industry Experts from Quantitative Analysis, Artificial Intelligence, Creative Content, Trading, Technology.
                          </text>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
            </div>

            <div className="csr-initiatives">
              <h2>Other Initiatives</h2>
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <img src={freePlatform} alt="Name Origin" />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="csr-feature">
                    <h4>Platform for Retail Investors</h4>
                    <text>
                      Provide tools to minimize losses of retail community and prepare
                      smaller investors for appropriate risk management, advanced investments.
                    </text>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <img src={financial} alt="Name Origin" />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="csr-feature">
                    <h4>Financial Literacy Training</h4>
                    <text>
                      Targeted for younger generation to improve financial
                      decision-making.
                    </text>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="community-section">
            <h2>Join the Shankh Community</h2>
            <p>Join us in transforming the wealth management landscape.
                           With Shankh, you're not just investor; you're part of a community striving for
                           financial success. Get started today and take the first step towards maximizing
                           your wealth potential.</p>
            <Button type="primary" onClick={showModal}>
              Get Started
            </Button>
          </div>
        </Content>
      </Layout>
      <HomePageFooter />
      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="full-screen-modal"
        width="100%"
        style={{ top: 0, padding: 0 }}
        bodyStyle={{ height: "100vh", padding: 0, margin: 0 }}
      >
        <SignIn onClose={handleCancel} />
      </Modal>
    </>
  );
};

export default Aboutus;
