import React, { useState } from "react";
import { Layout, Menu, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import logo from "../assets/images/shankh-logo-hz.svg"; // Ensure you have the correct path to your logo
import "../assets/styles/homeheader.css"; // Import the CSS file for styling
import { Link, useLocation } from "react-router-dom";

const { Header } = Layout;

const HomeHeader = ({ showModal }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 800);
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];

  const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  // Update mobile view state on window resize
  const handleResize = () => {
    setIsMobileView(window.innerWidth < 800);
  };

  // Add event listener for window resize
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Header className="header">
      <div className="logo">
        <img src={logo} alt="Shankh" />
      </div>
      <div className="menu-container">
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[currentPath]}
          className="menu"
        >
          <Menu.Item
            key="homepage"
            className={currentPath === "homepage" ? "selected-menu-item" : ""}
          >
            <Link to="/homepage">Home</Link>
          </Menu.Item>
          <Menu.Item
            key="about-us"
            className={currentPath === "about-us" ? "selected-menu-item" : ""}
          >
            <Link to="/about-us">About Us</Link>
          </Menu.Item>
          <Menu.Item
            key="products"
            className={currentPath === "products" ? "selected-menu-item" : ""}
          >
            <Link to="/products">Products</Link>
          </Menu.Item>
          <Menu.Item
            key="media"
            className={currentPath === "media" ? "selected-menu-item" : ""}
          >
            <Link to="/media">Media</Link>
          </Menu.Item>
          <Menu.Item
            key="career"
            className={currentPath === "career" ? "selected-menu-item" : ""}
          >
            <Link to="/career">Career</Link>
          </Menu.Item>
          <Menu.Item
            key="contact-us"
            className={currentPath === "contact-us" ? "selected-menu-item" : ""}
          >
            <Link to="/contact-us">Contact Us</Link>
          </Menu.Item>
        </Menu>
        <div>
          {!isMobileView && (
            <Button type="primary" onClick={showModal}>
              Login
            </Button>
          )}
        </div>
      </div>
      {/* Render mobile menu icon only on mobile view */}
      {isMobileView && (
        <div className="mobile-menu-icon">
          <MenuOutlined onClick={toggleDrawer} />
        </div>
      )}
      <Drawer
        title={<img src={logo} alt="Shankh" className="drawer-logo" />}
        placement="left"
        closable={true}
        onClose={toggleDrawer}
        visible={isDrawerVisible}
        className="home-drawer"
        footer={
          <div className="drawer-footer">
            <Button type="primary" onClick={showModal} block>
              Get Started
            </Button>
          </div>
        }
      >
        <Menu mode="vertical">
          <Menu.Item
            key="homepage"
            style={{ color: currentPath === "" || currentPath === "homepage" ? "green" : "white" }}
            onClick={toggleDrawer}
          >
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item
            key="about-us"
            style={{ color: currentPath === "about-us" ? "green" : "white" }}
            onClick={toggleDrawer}
          >
            <Link to="/about-us">About Us</Link>
          </Menu.Item>
          <Menu.Item
            key="products"
            style={{ color: currentPath === "products" ? "green" : "white" }}
            onClick={toggleDrawer}
          >
            <Link to="/products">Products</Link>
          </Menu.Item>
          <Menu.Item
            key="media"
            style={{ color: currentPath === "media" ? "green" : "white" }}
            onClick={toggleDrawer}
          >
            <Link to="/media">Media</Link>
          </Menu.Item>
          <Menu.Item
            key="career"
            style={{ color: currentPath === "career" ? "green" : "white" }}
            onClick={toggleDrawer}
          >
            <Link to="/career">Career</Link>
          </Menu.Item>
          <Menu.Item
            key="contact-us"
            style={{ color: currentPath === "contact-us" ? "green" : "white" }}
            onClick={toggleDrawer}
          >
            <Link to="/contact-us">Contact Us</Link>
          </Menu.Item>
        </Menu>
      </Drawer>
    </Header>
  );
};

export default HomeHeader;
