import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
} from "antd";
import signinbg from "../assets/images/img-signin.jpg";
import axios from "axios";
import Cookies from "js-cookie";
import "../assets/styles/signin.css";
import logo from "../assets/images/shankh-logo-hz.svg";

function onChange(checked) {
  console.log(`switch to ${checked}`);
}
const { Title } = Typography;
const { Header, Footer, Content } = Layout;

class SignIn extends Component {
  render() {
    const onFinish = async (values) => {
        try {
          const response = await axios.post(
            "http://127.0.0.1:8000/api/v1/token/",
            {
              username: values.email,
              password: values.password,
            }
          );
          const { access, is_complianceOfficer, is_advisorOfficer } = response.data;

          if (is_complianceOfficer) {
            Cookies.set("jwt", access, { expires: 10 });
            this.props.history.push("/dashboard");
            this.props.onClose();
          }else if(is_advisorOfficer){
            Cookies.set("jwt", access, { expires: 10 });
            window.location.href='advisory-dashboard'
            this.props.onClose();
          }
          else {
            alert("Login failed. You don't have this authority");
          }
        } catch (error) {
          console.error("Login failed:", error);
          alert("Login failed. Please check your credentials and try again.");
        }
      
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    return (
      <>
        <Layout className="layout-default-layout-signin">
          <div className="logo-signin">
            <img src={logo} alt="Shankh" />
          </div>
          <Content className="signin">
            <Title className="mb-10">Log In</Title>
            <Title className="font-regular text-muted" level={5}>
              Enter your email and password to sign in
            </Title>
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="username"
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                className="username"
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item className="forgot-password-text">
                <span>Forgot Password</span>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "48%" }}
                >
                  LOG IN
                </Button>
                <Button type="primary" href="/contact-us" className="create-acc-btn custom-button">Create Account</Button>
              </Form.Item>
            </Form>
          </Content>
        </Layout>
      </>
    );
  }
}
export default withRouter(SignIn);
